<template>
  <div class="flex flex-col gap-2">
    <section class="flex justify-between">
      <Button
        label="Atras"
        icon="pi pi-arrow-left"
        class="p-button-secondary p-mr-2 p-mb-2"
        @click="$router.push({ name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas' })"
        size="small"
      />
      <Button
        label="Crear entrada"
        icon="pi pi-plus"
        class="p-button-success p-mr-2 p-mb-2"
        @click="crearEntrada"
        v-if="!viendo"
        size="small"
      />
    </section>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="cl-1 flex flex-col">
        <label>Motivo</label>
        <Dropdown
          v-model="nuevaEntrada.Motivo"
          :options="motivos"
          optionLabel="Name"
          optionValue="Code"
          placeholder="Seleccione un motivo"
          class="w-full p-inputtext-xs"
        />
      </div>
      <div class="cl-2 flex flex-col">
        <label>Bodega</label>
        <Dropdown
          v-model="nuevaEntrada.WhsCode"
          :options="bodegas"
          optionLabel="WhsName"
          optionValue="WhsCode"
          placeholder="Seleccione una bodega"
          class="w-full p-inputtext-xs"
          filter
          @change="onChangeBodega"
        >
          <template #option="{ option }">
            <span>{{ option.WhsCode }} - {{ option.WhsName }}</span>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="grid grid-cols-1">
      <div class="cl-2 flex flex-col">
        <label for="Comentarios">Comentarios</label>
        <Textarea v-model="nuevaEntrada.Comments" class="w-full p-inputtext-xs" rows="2" cols="30"/>
      </div>
    </div>

    <section class="flex flex-col">
      <label>Articulo</label>
      <AutoComplete
        v-model="articuloSeleccionado"
        :suggestions="suggestionsArticulos"
        @complete="searchArticulos"
        class="p-inputtext-sm w-full text-xs"
        inputClass="w-full textxs"
        panelClass="text-xs h-panel"
        @item-select="agregarMedicamento"
        :disabled="!nuevaEntrada.WhsCode || !nuevaEntrada.Motivo"
      >
        <template #option="{ option }">
          <div>
            <p class="p-0 flex-wrap">{{
                option.ItemCode + ' - ' + option.ItemName + option.UserText
              }}</p>
          </div>
        </template>
      </AutoComplete>
    </section>
    <section class="">
      <DataTable :value="nuevaEntrada.Detalle" tableStyle="min-width: 50rem; font-size:0.75rem" size="small">
        <template #empty>
          <div class="flex flex-col items-center justify-center">
            <p class="text-sm">No hay articulos agregados</p>
          </div>
        </template>
        <Column>
          <template #body="{index}">
            <Button
              icon="pi pi-trash"
              class="p-button-danger p-mr-2 p-mb-2"
              @click="quitarLinea(index)"
              text rounded
            />
          </template>
        </Column>
        <Column field="ItemCode" header="Cod. Articulo"/>
        <Column field="ItemName" header="Articulo Descripción"/>
        <Column field="avgprice" header="Costo">
          <template #body="{data}">
            <span>{{ $h.formatCurrency(data.avgprice) }}</span>
          </template>
        </Column>
        <Column field="Quantity" header="Cantidad" style="width: 5%">
          <template #body="{data}">
            <InputNumber v-model="data.Quantity" class="p-inputtext-xs w-full"/>
          </template>
        </Column>
        <Column field="NumLote" header="Lote" style="width: 10%">
          <template #body="{data, index}">
            <Dropdown
              v-model="data.NumLote"
              :options="data.Lotes"
              optionLabel="NumLote"
              placeholder="Lote"
              editable
              @change="onChangeNumLote($event, index)"
            />
          </template>
        </Column>
        <Column field="FecVcto" header="Fec. Vecimiento" style="width: 10%">
          <template #body="{data}">
            <input type="date" class="p-inputtext p-inputtext-xs w-full" v-model="data.FecVcto"/>
          </template>
        </Column>
      </DataTable>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import BodegasService from '../../pages/ajustesInventario/services/bodegasInventario'
import AjustesInventarioService
  from '@/apps/pharmasan/logistica/V1/views/pages/ajustesInventario/services/ajustesInventario'
import { useAjustesInventario } from '../../pages/ajustesInventario/store/ajustesInventario.store'
import Swal from 'sweetalert2'

// Stores
const AjustesInventarioStore = useAjustesInventario()

// Services
const _BodegasService = new BodegasService()
const _AjustesInventarioService = ref(new AjustesInventarioService())

// Computed
const nuevaEntrada = computed(() => AjustesInventarioStore._NuevaEntrada)

// Refs
const bodegas = ref([])
const motivos = ref([])
const articuloSeleccionado = ref(null)
const suggestionsArticulos = ref([])
const router = useRouter()
const route = useRoute()
const viendo = ref(false)

// Methods
const searchArticulos = () => {
  _AjustesInventarioService.value.getArticulos(articuloSeleccionado.value, nuevaEntrada.value.WhsCode).then(({ data }) => {
    suggestionsArticulos.value = data
  })
}
const agregarMedicamento = () => {
  AjustesInventarioStore.agregarDetalle(articuloSeleccionado.value)
  articuloSeleccionado.value = null
}
const quitarLinea = (index) => {
  AjustesInventarioStore.quitarLinea(index)
}
const crearEntrada = async () => {
  await Swal.fire({
    title: 'Advertencia',
    text: '¿Esta seguro de crear la entrada?',
    icon: 'warning',
    showCancelButton: true
  }).then((result) => {
    if (result.isConfirmed) {
      if (!nuevaEntrada.value.Motivo) {
        return Swal.fire({
          title: 'Advertencia',
          text: 'El motivo es obligatorio',
          icon: 'warning'
        })
      }
      if (!nuevaEntrada.value.WhsCode) {
        return Swal.fire({
          title: 'Advertencia',
          text: 'La bodega es obligatoria',
          icon: 'warning'
        })
      }
      if (nuevaEntrada.value.Detalle.length < 1) {
        return Swal.fire({
          title: 'Advertencia',
          text: 'Debe seleccionar al menos 1 item',
          icon: 'warning'
        })
      }
      nuevaEntrada.value.Detalle.map(item => {
        if (!item.avgprice) {
          return Swal.fire({
            title: 'Advertencia',
            text: 'El costo es obligatorio',
            icon: 'warning'
          })
        }
      })
      _AjustesInventarioService.value.crearAjuste(nuevaEntrada.value).then((data) => {
        Swal.fire({
          title: 'Entrada creada',
          text: 'La entrada se ha creado correctamente',
          icon: 'success'
        }).then(() => {
          router.push({ name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.entradas' })
        })
      }).catch((error) => {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error'
        })
      })
    }
  })
}
const onChangeBodega = () => {
  if (nuevaEntrada.value.Detalle.length) {
    AjustesInventarioStore.resetLotes()
  }
}
const onChangeNumLote = (event, index) => {
  if (event.value.FecVcto && event.value.NumLote) {
    nuevaEntrada.value.Detalle[index].FecVcto = event.value.FecVcto
    nuevaEntrada.value.Detalle[index].NumLote = event.value.NumLote
  }
}

onMounted(async () => {
  if (route.params.Id) {
    viendo.value = true
    await AjustesInventarioStore.getAjuste(route.params.Id)
  } else {
    AjustesInventarioStore.resetData()
  }
  _BodegasService.listarBodegasTodas({ tipoBodega: 3 }).then(({ data }) => {
    bodegas.value = data
  })
  _AjustesInventarioService.value.getMotivos().then(({ data }) => {
    motivos.value = data
  })
})
</script>

<style scoped>

</style>
